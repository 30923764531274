var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-wrap justify-content-between m-auto"},[(_vm.services.length === 0)?_c('p',{staticClass:"text-dark m-auto"},[_vm._v(_vm._s(_vm.$t('storeWithoutServices')))]):_vm._l((_vm.services),function(service,index){return _c('div',{key:index,staticClass:"col-lg-6 mb-4 cursor-pointer",attrs:{"hidden":service.category.name !== _vm.tab &&
      _vm.selectedTab !== 'home' &&
      _vm.selectedTab !== null}},[(
        _vm.selectedTab === 'home' ||
        service.category.name === _vm.tab ||
        (_vm.selectedTab === null && _vm.tab === null)
      )?_c('card',{staticClass:"card-chart card-chart-pie mb-0 box-text"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-9 row",on:{"click":function($event){return _vm.goToStoreService(_vm.slug, service.uuid, service.slug)}}},[_c('h2',{staticClass:"col-12 mb-0 font-weight-bold text-capitalize ml-3"},[_vm._v(" "+_vm._s(service.name)+" "),_c('span',{staticClass:"font-weight-light time-size"},[_vm._v("["+_vm._s(service.time)+"m]")])]),_c('p',{staticClass:"col-12 align-self-start ml-3"},[_vm._v(" "+_vm._s(service.description)+" ")]),_c('h3',{staticClass:"col-12 align-self-end card-title font-weight-bold ml-3",class:service.on_promotion ? 'text-danger' : ''},[(service.on_promotion)?_c('del',{staticClass:"mr-2 text-dark"},[_vm._v(" "+_vm._s(service.price.toFixed(2))+"€ ")]):_vm._e(),_vm._v(" "+_vm._s(service.on_promotion ? `${( (service.price * (100 - service.discount)) / 100 ).toFixed(2)}€` : `${service.price.toFixed(2)}€`)+" ")])]),_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"row chart-area p-0 m-0"},[(_vm.controlServicesPictures(service.pictures))?_c('img',{staticClass:"col-12 p-0 img-services mb-md-1 mb-2",attrs:{"src":_vm.controlServicesPictures(service.pictures),"alt":_vm.$t('alt.serviceImage'),"width":"20rem","height":"20rem","loading":"lazy"},on:{"error":_vm.replaceByDefault,"click":function($event){return _vm.goToStoreService(_vm.slug, service.uuid, service.slug)}}}):_c('img',{staticClass:"col-12 p-0 img-services mb-md-1 mb-2",attrs:{"src":require("@/assets/img/img-default.png"),"alt":_vm.$t('alt.serviceImage'),"width":"20rem","height":"20rem","loading":"lazy"},on:{"click":function($event){return _vm.goToStoreService(_vm.slug, service.uuid, service.slug)}}}),_c('base-button',{staticClass:"col-12 btn m-0 btn-small-font px-0",attrs:{"type":"tertiary"},on:{"click":function($event){return _vm.goToServiceBooking(_vm.slug, service.uuid, service.slug)}}},[_vm._v(_vm._s(_vm.$t("book")))])],1)])])]):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }