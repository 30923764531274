<template>
  <div class="row mx-0">
    <header-image>
      <img
        v-if="$route.name === 'storeList' || $route.name === 'storeListFiltered'"
        src="@/assets/img/header-img.jpg"
        alt="Header picture"
        class="card-img header-img"
        width="100%"
        height="100%"
        loading="lazy"
      />
    </header-image>
    <search-bar 
      class="w-100 margin-search"
      @stores-services="getStoresServices"
      :selectedStoreCategory="selectedStoreCategory"
    />

    <div v-if="isLoadingCategories" class="w-100">
      <Spinner  classes="mx-auto my-4" />
    </div>
    <section v-if="storeCategories.length > 0 && !isLoadingCategories" class="my-3 mx-auto">
      <TabsCarousel
        ref="tabsCarousel"
        :tabs="storeCategories"
        @tabChanged="setSelectedTab"
        :withAllBtn="false"
      />
    </section>
    <section class="p-4">
      <div class="col-12 pb-4">
        <h1 class="text-black text-center font-weight-bold">{{ $t('bookAsAndWhenYouWant') }}</h1>
        <p class="text-black pb-4 paragraph">{{ $t('firstParagraphBookAsAndWhenYouWant') }}</p>
        <p class="text-black">{{ $t('secondParagraphBookAsAndWhenYouWant') }}</p>
      </div>
      <div class="d-md-flex">
        <div class="col-12 col-md-6 pb-4">
          <h2 class="text-black text-center">{{ $t('manageYourAppointments') }}</h2>
          <p class="text-black">{{ $t('paragraphManageYourAppointments') }}</p>
        </div>

        <div class="col-12 col-md-6 pb-4">
          <h2 class="text-black text-center">{{ $t('simplicityAndEfficiency') }}</h2>
          <p class="text-black">{{ $t('paragraphSimplicityAndEfficiency') }} <strong class='font-weight-bold'>{{ $t('strongSimplicityAndEfficiency') }}</strong></p>
        </div>
      </div>
    </section>
    <div class="col-12 mb-2 box-text row" ref="headerStoreList">
      <div class="col-12 d-flex ml-2 mb-2">
        <h1 class="mb-2 font-weight-bold">{{ serviceName ? serviceName : $tc("business", 2) }}</h1>
        <p class="ml-2 pt-2">
          ({{ pagination.totalItems }} {{ $tc("found", pagination.totalItems === 1 ? 1 : 2) }})
        </p>
      </div>
      <!-- <div class="col-12 col-sm-4 col-md-2 filtersContainer d-flex flex-wrap flex-sm-nowrap ml-2" ref="headerStoreList">
        <drop-down tag="div" class="col-12 p-0 m-0">
          <base-button
            simple
            data-toggle="dropdown"
            class="btn-small-font mt-1 col-12 m-0"
            >{{ $t("sort") }}
            <em class="tim-icons icon-minimal-down ml-2"></em>
          </base-button>
        </drop-down>
        <base-button simple class="col-12 btn-small-font mt-3 mt-sm-1 ml-sm-4">{{
          $t("filtersAndLocation")
        }}</base-button>
      </div> -->
    </div>
    <div ref="storeList" class="col-12 p-0 px-3">
      <card
        v-for="(store, index) in stores"
        :key="index"
        type="nav-tabs"
        class="mb-3"
      >
        <blockquote class="blockquote mb-0 p-2 row align-items-center">
          <div
            class="text-center mx-2 mx-md-0 col-12 col-md-2 cursor-pointer"
            @click="goToDetailView(store.slug)"
          >
            <img
              v-if="controlPictures(store)"
              slot="image"
              class="rounded img-small-screen shadow-sm"
              :src="controlPictures(store)"
              @error="replaceByDefault"
              :alt="$t('alt.storeImage')"
              width="250rem"
              height="100rem"
              loading="lazy"
            />
            <img
              v-else
              slot="image"
              class="rounded img-small-screen shadow-sm"
              src="@/assets/img/img-default.png"
              :alt="$t('alt.storeImage')"
              width="250rem"
              height="100rem"
              loading="lazy"
            />
          </div>
          <div class="col-12 col-md-10 box-text mt-4 mt-md-0 mx-2 mx-md-0">
              <div class="d-flex flex-wrap">
                <h1
                  class="mb-0 d-block font-weight-bold cursor-pointer mr-4"
                  @click="goToDetailView(store.slug)"
                >
                  {{ store.name }}
                </h1>
                <div class="d-flex align-items-center">
                  <i  class="lni star lni-star-filled" />
                  <span class="font-weight-bold ml-1">{{ store.reviews_mean_value }}</span>/{{ maxScore }}
                  <span class="ml-1">({{ store.reviews_amount }})</span>

                </div>
              </div>
              <p>{{ store.address }}</p>
              <p>{{ store.phone }}</p>
              <hr class="mt-8 mb-0" />
          </div>
          <div class="col-12 my-4">
            <ServiceInformation
              :services="store.services"
              :slug="store.slug"
            ></ServiceInformation>
          </div>
        </blockquote>
      </card>
      <Spinner v-if="isLoadingStores" classes="mx-auto my-4"></Spinner>
      <card class="text-center">
        <base-button
            v-if="stores.length > 0"
            simple
            class="btn-small-font mt-1 mx-auto"
            @click="backToTop()"
            >{{ $t("backToTop") }}
          </base-button>
      </card>
    </div>
  </div>
</template>

<script>
import {
  Card,
  // DropDown,
  HeaderImage,
  SearchBar,
  Spinner,
  TabsCarousel
} from "@/components/index";
import ServiceInformation from "./Components/ServiceInformation";
import { mapState } from "vuex";
import defaultImage from "../../assets/img/img-default.png";

export default {
  name: "StoreList",
  data: () => {
    return {
      route: "/stores",
      stores: [],
      serviceName: null,
      screenWidth: screen.width,
      pagination: {
        perPage: 10,
        lastPage: 1,
        totalItems: 0,
      },
      currentPage: 1 ,
      isLoadingStores: false,
      isLoadingCategories: false,
      pagesLoad: [],
      storeCategories: [],
      selectedStoreCategory: null,
      maxScore: 5
    };
  },
  computed: {
    ...mapState(["defaultTitle"]),
  },
  methods: {
    async getItems(page = 0, filters) {
      if(this.pagesLoad.includes(page)) return;

      try {
        this.isLoadingStores= true;
        let route = `${this.route}?page=${page}`;
        let params = {};

        if(filters) {
          route = this.route;
          params = {...filters}
        }
        
        let response = await this.axios.get(route, { params } );
        if (response && response.data.status === "success") {
          this.pagesLoad.push(page);
          
          if(response.data.data.stores) {
            const data = response.data.data.stores.data;
            this.stores = filters? data : [...this.stores, ...data];
            data.forEach((store) => (store.id = store.slug));
  
            this.currentPage = response.data.data.stores.current_page;
            this.pagination.perPage = response.data.data.stores.per_page;
            this.pagination.lastPage = response.data.data.stores.last_page;
            this.pagination.totalItems = response.data.data.stores.total;
          }
        }
      } catch (error) {
       console.log(error); 
      }
      this.isLoadingStores= false;
    },
    async getCategories() {
      this.isLoadingCategories = true;
      try {
        const route = 'store-categories/all';
        let response = await this.axios.get(route);
        
        if (response && response.data.status === "success") {
          let storeCategories = response.data.data.store_categories;
          storeCategories = storeCategories.map( category => {
            category.name = this.$t(`storeCategories.${category.code}`);
            return category;
          });
          this.storeCategories =  storeCategories;
        }
      } catch (error) {
        console.log(error);
        return [];
      }
      this.isLoadingCategories = false;
      return [];
    },
    goToDetailView(itemSlug) {
      this.$router.push({ name: "storeView", params: { slug: itemSlug } });
    },
    goToLogin() {
      this.$router.push({ name: "login" });
    },
    goToRegister() {
      this.$router.push({ name: "register" });
    },
    goToStoreService(itemSlug, uuid) {
      this.$router.push({
        name: "storeService",
        params: { slug: itemSlug, uuid: uuid },
      });
    },
    setSelectedTab(tab) {
      if(!this.selectedStoreCategory) {
        this.$router.push({ name: 'storeListFiltered', params: { slug: String(tab.code).toLowerCase() } });
        return;
      }
      
      const isSameCategory = this.selectedStoreCategory.code === tab.code;
      if(isSameCategory) {
        this.$router.push({ name: 'storeList'});
      } else {
        this.$router.push({ name: 'storeListFiltered', params: { slug: String(tab.code).toLowerCase() } });
      }
    },
    controlPictures(store) {
      let mainPicture = null;
      if (store.pictures.length > 0) {
        store.pictures.forEach((picture) => {
          if (picture.type === "2") {
            mainPicture = picture.url;
          }
        });
      }
      return mainPicture;
    },
    replaceByDefault(event) {
      event.target.src = defaultImage;
    },
    getStoresServices(filters, serviceName) {
      this.pagesLoad = [];
      this.stores = [];
      this.getItems(null, filters);
      if(serviceName) this.serviceName = serviceName;
    },
    backToTop(){
      if( this.$refs && this.$refs.headerStoreList ) {
        this.$refs.headerStoreList.scrollIntoView({block: "start", behavior: "smooth"})
      } 
    },
    onScrollEvent(){
      let storeList = this.$refs.storeList;
      if(storeList){
        let listHeight = Math.round(storeList.getBoundingClientRect().bottom);
        let haveMorePages = this.currentPage < this.pagination.lastPage;
        
        if(storeList && listHeight <= window.innerHeight && haveMorePages){
          
          this.getItems(++this.currentPage);
        }
      }
    }
  },
  async mounted() {
    const isFiltering = this.$route.name === 'storeListFiltered';
    await this.getCategories();

    if (isFiltering) {
      const categoryFiltering = String(this.$route.params.slug).toUpperCase()
      const categorySelectedData = this.storeCategories.find( category => category.code === categoryFiltering);
      
      if(this.$refs.tabsCarousel) {
        this.$refs.tabsCarousel.setSelectedTab(categorySelectedData);
      }
      this.selectedStoreCategory = categorySelectedData;
    } else {
      this.getItems(this.currentPage);
    }
    
    window.addEventListener("scroll", this.onScrollEvent);
  },
  destroyed(){
    window.removeEventListener("scroll", this.onScrollEvent)
  },
  metaInfo() {
    return {
      title: `${this.defaultTitle} - ${this.$t('vueMeta.storeList.title')}`,
      meta: [
        {name: 'og:title', property: 'og:title', content: `${this.defaultTitle} - ${this.$t('vueMeta.storeList.title')}`},
        {name: 'og:type', property: 'og:type', content: 'website'},
        {name: 'og:url', property: 'og:url', content: `${process.env.VUE_APP_URL}`},
        {name: 'og:description', property: 'og:description', content: this.$t('vueMeta.storeList.description')},
        {name: 'description', content: this.$t('vueMeta.storeList.description')},
        {name: 'og:image', property: 'og:image', content: 'https://as-saloon.com/img/Logo_AS_Transparent.5e298134.png'},
        {name: 'og:site_name', property: 'og:site_name', content: process.env.VUE_APP_AS_NAME},
      ]
    }
  },
  components: {
    Card,
    // DropDown,
    HeaderImage,
    SearchBar,
    ServiceInformation,
    Spinner,
    TabsCarousel
  },
};
</script>

<style lang="scss" scoped>
@import  '../../assets/scss/white-dashboard.scss';

.box-text h1 {
  font-size: calc(20px + (50 - 20) * ((100vw - 300px) / (1600 - 300)));
  color: black;
}
.box-text h2 {
  font-size: calc(20px + (50 - 20) * ((100vw - 300px) / (1600 - 300)));
  color: black;
}
.box-text p {
  font-size: calc(10px + (24 - 10) * ((100vw - 300px) / (1600 - 300)));
  color: black;
}
.cursor-pointer {
  cursor: pointer;
}
.margin-search {
  margin-top: -370px;
  height: 100px;
}
.img-small-screen {
  max-height: 130px;
}
.btn-small-font {
  font-size: 0.7rem;
}
.lni-star-filled {
  color: $tertiary;
}
@media (max-width: 768px) {
  .btn-small-font {
    font-size: 0.8rem;
  }
  .img-small-screen {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
  }
}
@media (min-width: 768px) {
  .filtersContainer{
    max-width: 14rem;
  }
  .img-small-screen {
    object-fit: cover;
  }
}
</style>
