<template>
  <div class="d-flex flex-wrap justify-content-between m-auto">
    <p v-if="services.length === 0" class="text-dark m-auto">{{ $t('storeWithoutServices') }}</p>
    <div
      v-else
      v-for="(service, index) in services"
      :key="index"
      :hidden="
        service.category.name !== tab &&
        selectedTab !== 'home' &&
        selectedTab !== null
      "
      class="col-lg-6 mb-4 cursor-pointer"
    >
      <card
        v-if="
          selectedTab === 'home' ||
          service.category.name === tab ||
          (selectedTab === null && tab === null)
        "
        class="card-chart card-chart-pie mb-0 box-text"
      >
        <div class="row">
          <div class="col-sm-9 row" @click="goToStoreService(slug, service.uuid, service.slug)">
            <h2 class="col-12 mb-0 font-weight-bold text-capitalize ml-3">
              {{ service.name }}
              <span class="font-weight-light time-size"
                >[{{ service.time }}m]</span
              >
            </h2>
            <p class="col-12 align-self-start ml-3">
              {{ service.description }}
            </p>
            <h3
              class="col-12 align-self-end card-title font-weight-bold ml-3"
              :class="service.on_promotion ? 'text-danger' : ''"
            >
              <del v-if="service.on_promotion" class="mr-2 text-dark">
                {{ service.price.toFixed(2) }}€
              </del>
              {{
                service.on_promotion
                  ? `${(
                      (service.price * (100 - service.discount)) /
                      100
                    ).toFixed(2)}€`
                  : `${service.price.toFixed(2)}€`
              }}
            </h3>
          </div>
          <div class="col-sm-3">
            <div class="row chart-area p-0 m-0">
              <img
                v-if="controlServicesPictures(service.pictures)"
                :src="controlServicesPictures(service.pictures)"
                :alt="$t('alt.serviceImage')"
                @error="replaceByDefault"
                @click="goToStoreService(slug, service.uuid, service.slug)"
                class="col-12 p-0 img-services mb-md-1 mb-2"
                width="20rem"
                height="20rem"
                loading="lazy"
              />
              <img
                v-else
                src="@/assets/img/img-default.png"
                :alt="$t('alt.serviceImage')"
                @click="goToStoreService(slug, service.uuid, service.slug)"
                class="col-12 p-0 img-services mb-md-1 mb-2"
                width="20rem"
                height="20rem"
                loading="lazy"
              />
              <base-button
                class="col-12 btn m-0 btn-small-font px-0"
                type="tertiary"
                @click="goToServiceBooking(slug, service.uuid, service.slug)"
                >{{ $t("book") }}</base-button
              >
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { Card } from "@/components/index";
import defaultImage from "../../../assets/img/img-default.png";

export default {
  name: "ServiceInformation",
  props: {
    services: {
      type: Array,
    },
    tab: {
      type: String,
      default: null,
    },
    selectedTab: {
      type: String,
      default: null,
    },
    slug: {
      type: String,
      default: null,
    },
  },
  methods: {
    controlServicesPictures(pictures) {
      let mainPicture = null;
      if (pictures.length > 0) {
        pictures.forEach((picture) => {
          if (picture.type === "1") {
            mainPicture = picture.url;
          }
        });
      }
      return mainPicture;
    },
    goToStoreService(itemSlug, uuid, serviceSlug) {
      this.$router.push({
        name: "storeService",
        params: { slug: itemSlug, uuid: uuid, serviceSlug: serviceSlug },
      });
    },
    goToServiceBooking(slug, uuid, serviceSlug) {
      this.$router.push({
        name: "serviceBooking",
        params: { slug: slug, uuid: uuid, serviceSlug: serviceSlug },
      });
    },
    replaceByDefault(event) {
      event.target.src = defaultImage;
    },
  },
  components: {
    Card,
  },
};
</script>

<style scoped>
.img-services {
  width: 100px;
  height: 250px;
  object-fit: cover;
}
.cursor-pointer {
  cursor: pointer;
}
.time-size {
  font-size: 15px;
}
@media (min-width: 576px) {
  .img-services {
    width: 100px;
    height: 120px;
  }
}
</style>
